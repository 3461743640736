@import url("https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css");
/*@import url("https://cdn.jsdelivr.net/npm/lt-themify-icons@1.1.0/themify-icons.css");*/
@import url("https://cdn.lineicons.com/3.0/LineIcons.css");

@import "_quillViewer.css";
@import "_quill.css";

    *{
        box-sizing: border-box;
    }

    input, select, textarea{
        box-sizing: border-box;
    }

    .ql-editor {
        min-height: 400px;
        background-color: #fff;
    }


    html {
        background-color: white;
        font-size: 16px;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        min-width: 300px;
        /* overflow-x: hidden;
        overflow-y: scroll; */
        text-rendering: optimizeLegibility;
        -webkit-text-size-adjust: 100%;
        -moz-text-size-adjust: 100%;
        -ms-text-size-adjust: 100%;
        text-size-adjust: 100%;
    }

    body{
        padding:0;
        margin:0;
        overflow-x: hidden;
    }

    body{
        font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
        color: #4a4a4a;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
    }